<template>
  <div>
    <div class="flex">
      <slot />
      <VLabel
        :text="label"
        :name="name"
        :is-required="isRequired"
        :size="labelSize"
        :class="{ 'sr-only': isLabelHidden }"
      />
    </div>
    <div class="relative mt-1 rounded-md shadow-sm">
      <input
        :id="name"
        lang="de"
        :name="name"
        :type="fieldType"
        :min="setMin"
        :max="setMax"
        :value="inputValue"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :disabled="disabled"
        :class="{ 'border-cred': !!errorMessage }"
        class="block w-full py-3 text-gray-900 border-gray-500 rounded-md shadow-sm disabled:bg-gray-100 disabled:text-gray-500 focus:ring-cblack focus:border-cblack sm:text-sm"
        @input="onChangeHandler"
        @blur="handleBlur"
      />
      <div
        v-if="isClearButtonVisible"
        class="absolute inset-y-0 flex items-center right-3.5"
      >
        <button
          aria-label="clear"
          class="inline-flex items-center justify-center p-1.5 bg-gray-300 rounded-full hover:bg-gray-400"
          @click="onClickClearButton()"
        >
          <XIcon class="w-2.5 h-2.5 text-white" aria-hidden="true" />
        </button>
      </div>
      <div
        v-if="isShowPasswordButtonEnabled"
        class="absolute inset-y-0 flex items-center right-3.5"
      >
        <button
          type="button"
          :aria-label="isShowPassword ? 'hide' : 'show'"
          class="inline-flex"
          @click="togglePasswordShow()"
        >
          <OpenEyeIcon
            v-if="isShowPassword"
            class="text-cblack"
            aria-hidden="true"
          />
          <ClosedEyeIcon v-else class="text-cblack" aria-hidden="true" />
        </button>
      </div>
      <div
        v-show="errorMessage"
        class="absolute inset-y-0 flex items-center pr-3 pointer-events-none"
        :class="
          isShowPasswordButtonEnabled || type === 'number'
            ? 'right-6'
            : 'right-0'
        "
      >
        <ExclamationCircleIcon
          class="w-7 h-7 text-cred"
          aria-hidden="true"
          :class="{ 'mr-6': type === 'date' }"
        />
      </div>
    </div>
    <div v-if="unitsLabel" class="relative h-0.1">
      <span
        type="button"
        class="relative bottom-12 sm:bottom-11 left-13/20 border-l-1 border-gray-500 py-2.5 pl-2 sm:py-2.2 h-12 sm:h-11"
      >
        {{ unitsLabel }}
      </span>
    </div>
    <p v-show="errorMessage && showError" class="mt-2 text-sm text-cred">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, ref } from 'vue'
import { Size } from '@/types/enums'
import { useField } from 'vee-validate'
import * as yup from 'yup'
import ExclamationCircleIcon from '@/components/icons/ExclamationCircleIcon.vue'
import XIcon from '@/components/icons/XIcon.vue'
import VLabel from '@/components/VLabel.vue'
import ClosedEyeIcon from '@/components/icons/ClosedEyeIcon.vue'
import OpenEyeIcon from '@/components/icons/OpenEyeIcon.vue'

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: [String, Number],
    default: () => {
      return ''
    },
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  successMessage: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isClearButtonEnabled: {
    type: Boolean,
    default: false,
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  autocomplete: {
    type: String,
    default: '',
  },
  labelSize: {
    type: String as PropType<Size>,
    default: Size.sm,
  },
  showError: {
    type: Boolean,
    default: true,
  },
  unitsLabel: {
    type: String,
    default: '',
  },
  min: {
    type: [Number, String],
    default: null,
  },
  max: {
    type: [Number, String],
    default: null,
  },
  rules: {
    type: Object as PropType<yup.BaseSchema<any>>,
    default: () => {
      undefined
    },
  },
  isShowPasswordButtonEnabled: {
    type: Boolean,
    default: false,
  },
  isLabelHidden: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['clear'])

const isShowPassword = ref<boolean>(props.type != 'password')
const fieldType = ref(props.type)

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  resetField,
} = useField(props.name, props.rules, {
  initialValue: props.value,
})

watch(inputValue, (newValue) => {
  if (props.type === 'tel' && newValue && typeof newValue === 'string') {
    inputValue.value = newValue
      .replace(/[^0-9+ ]/g, '')
      .replace(/(\..*)\./g, '$1')
  }
})

function onChangeHandler(event: Event) {
  if (['text', 'email'].includes(props.type)) {
    const inputValue = (event.target as HTMLInputElement).value
    handleChange(inputValue.trim())
  } else {
    handleChange(event)
  }
}

// MARK: Clear Button

const isClearButtonVisible = computed(() => {
  if (typeof inputValue.value === 'string') {
    return props.isClearButtonEnabled && inputValue.value.length > 0
  }
  return false
})

function onClickClearButton() {
  resetField()
  emit('clear')
}

function togglePasswordShow() {
  isShowPassword.value = !isShowPassword.value
  if (fieldType.value === 'password') return (fieldType.value = 'text')
  else return (fieldType.value = 'password')
}

const setMin = computed(() => {
  if (props.type === 'date' || props.type === 'number') {
    return props.min != null ? props.min : 0
  }

  return null
})

const setMax = computed(() => {
  if (props.type === 'number') {
    return props.max != null ? props.max : 9999999
  }

  if (props.type === 'date') {
    return props.max != null ? props.max : null
  }

  return null
})
</script>
